<template>
  <div class="page-content">
    <div class="content-box" v-loading="loading">
      <div class="log-box mb-24">
        <SectionTitle title="学生日志" />
        <div>
          <el-row>
            <el-col>
              <div class="mr-8">岗位名称：</div>
              <div>{{ logInfo.JobName }}</div>
            </el-col>
            <el-col>
              <div class="mr-8">日志类型：</div>
              <div>{{ logInfo.Type_V }}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <div class="mr-8">学生姓名：</div>
              <div>{{ logInfo.StudentName }}</div>
            </el-col>
            <el-col>
              <div class="mr-8">提交时间：</div>
              <div>{{ formartDate(logInfo.Date, "all") }}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <div class="mr-8">日志内容：</div>
              <div>{{ logInfo.Content }}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <div class="mr-8">明日计划：</div>
              <div>{{ logInfo.ToDo }}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <div class="mr-8">心得体会：</div>
              <div>{{ logInfo.Question }}</div>
            </el-col>
          </el-row>
          <div v-if="!logInfo.Details">
            <el-row>
              <el-col>
                <div class="mr-8">工作项目名称：</div>
                <div></div>
              </el-col>
              <el-col>
                <div class="mr-8">项目产出：</div>
                <div></div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="reply-box">
        <SectionTitle title="指导老师回复" />
        <el-form ref="form" :model="logInfo" label-width="60px">
          <el-form-item label="回复：">
            <el-input
              v-model="logInfo.Comment"
              type="textarea"
              resize="none"
              rows="5"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="form-footer">
          <el-button type="primary" @click="handleSubmit" :loading="btnLoading"
            >提交</el-button
          >
          <el-button @click="$router.go(-1)">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import { getInfo, comment, read } from "@/api/JobDaily";
import contentTools from "@/mixins/content-tools";
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    isCreate: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    },
    isView: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SectionTitle
  },
  mixins: [contentTools],
  data() {
    return {
      btnLoading: false,
      loading: false,
      logInfo: {}
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        await this.getLogInfo();
        await this.handleRead();
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit() {
      try {
        this.btnLoading = true;
        let params = {};
        params.id = this.id;
        params.content = this.logInfo.Comment;
        const res = await comment(params);
        if (res.IsSuccess) {
          this.$message.success("提交成功");
          this.$router.go(-1);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.btnLoading = false;
      }
    },
    async handleRead() {
      await read({ id: this.id });
    },
    async getLogInfo() {
      const res = await getInfo(this.id);
      if (res.IsSuccess) {
        this.logInfo = res.Result;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content-box {
  width: 850px;
  margin: 0 auto;
}
.el-row {
  display: flex;
  margin-bottom: 24px;
  .el-col {
    display: flex;
    align-items: center;
  }
}
</style>
